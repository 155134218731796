import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'

import LocaleManager from '../components/LocaleManager'
import ImageHeadline from '../components/home/ImageHeadline'
import ProductShowcase from '../components/productShowcase'
import InstagramShowcase from '../components/home/InstagramShowcase'
import SEO from '../components/seo'
import FreeShipping from '../components/home/FreeShipping'
import IndexCategory from '../components/home/IndexCategory'
import FullImageHero from '../components/home/FullImageHero'

interface IndexProps {
  data: GatsbyTypes.IndexQuery
  pageContext: {
    locale: string
  }
}

const IndexPage: React.FC<IndexProps> = ({ data, pageContext: { locale } }) => (
  <LocaleManager locale={locale}>
    <FormattedMessage id="home">
      {(translation) => (
        <SEO
          title={translation ? translation.toString() : ''}
          description={data.description && data.description.translation}
          keywords={['belt', 'sweden', 'skärp']}
          lang={locale}
        />
      )}
    </FormattedMessage>

    {/* <HeroImageText locale={locale} titleHeader={data.titleHeader} featuredProduct={data.featuredProduct} /> */}
    <FullImageHero
      title={data.titleHeader?.titleBranding}
      subtitle={data.titleHeader?.text?.text}
      cta={data.titleHeader?.cta?.text}
      link={data.titleHeader?.cta?.link}
      gatsbyImage={data.heroImage?.childImageSharp?.fluid}
      locale={locale}
    />

    {data.leatherIndexCategory && (
      <div className="content">
        <IndexCategory
          title={data.leatherIndexCategory.title}
          body={
            data.leatherIndexCategory.body && data.leatherIndexCategory.body.body
          }
          products={data.leatherIndexCategory.belts}
          locale={locale}
        />
      </div>
    )}

    <ImageHeadline
      large={true}
      gatsbyImage={data.middleBg?.childImageSharp?.fluid}
    />

    {data.nylonIndexCategory && (
      <div className="content">
        <IndexCategory
          title={data.nylonIndexCategory.title}
          body={
            data.nylonIndexCategory.body && data.nylonIndexCategory.body.body
          }
          products={data.nylonIndexCategory.belts}
          locale={locale}
        />
      </div>
    )}

    <FreeShipping />

    <div className="content">
      <InstagramShowcase locale={locale} />
    </div>

    <ImageHeadline gatsbyImage={data.bottomBg?.childImageSharp?.fluid} />
  </LocaleManager>
)

export const query = graphql`
  fragment Product on ContentfulProduct {
    id
    price
    newPrice {
      sek
      eur
      usd
    }
    discount
    fullPrice {
      sek
      eur
      usd
    }
    outOfStock
    productDescription {
      productDescription
    }
    slug
    category {
      title {
        title
      }
    }
    image {
      fixed(width: 350) {
        ...GatsbyContentfulFixed
      }
    }
    productName {
      productName
    }
  }
  fragment IndexCategory on ContentfulIndexCategory {
    title
    body {
      body
    }
    belts {
      ...Product
    }
  }
  query Index($locale: String) {
    description: contentfulTranslation(
      key: { eq: "index-description" }
      node_locale: { eq: $locale }
    ) {
      translation
    }
    middleBg: file(relativePath: { eq: "ebba-hero-4-alt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroImage: file(relativePath: { eq: "index/noret_sgd.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomBg: file(relativePath: { eq: "jamtland.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leatherIndexCategory: contentfulIndexCategory(
      sectionKey: { eq: "leatherBelts" }
      node_locale: { eq: $locale }
    ) {
      ...IndexCategory
    }
    nylonIndexCategory: contentfulIndexCategory(
      sectionKey: { eq: "nylonBelts" }
      node_locale: { eq: $locale }
    ) {
      ...IndexCategory
    }
    products: allContentfulProduct(
      limit: 3
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          ...Product
        }
      }
    }
    titleHeader: contentfulBranding(
      title: { eq: "Hero" }
      node_locale: { eq: $locale }
    ) {
      titleBranding
      cta {
        text
        link
      }
      text {
        text
      }
    }
    featuredProduct: contentfulProduct(
      productName: { productName: { eq: "Noret" } }
      node_locale: { eq: $locale }
    ) {
      ...Product
    }
  }
`

export default IndexPage
