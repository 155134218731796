import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { breakpointsDown, colors, spacings } from '../../styling/constants'
import LargeButton from '../LargeButton'

type Size = 'variable' | 'fixed'

const TextWrapper = styled.div<SizeProps>`
  max-width: ${(props) => (props.size == 'variable' ? '60rem' : '40rem')};
  color: ${colors.christmasGreen};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${(props) => (props.size == 'variable' ? 0 : '8rem 0')};

  padding: 0 ${spacings.horizontalMobile};

  @media ${breakpointsDown.tablet} {
    margin: 0;
  }

  h1,
  p {
    color: ${colors.darkGray};
  }
`

interface SizeProps {
  size: Size
}

const HeroImage = styled(BackgroundImage)<SizeProps>`
  width: 100%;
  height: ${(props) => (props.size == 'variable' ? 'auto' : '85vh')};
  padding: ${(props) => (props.size == 'variable' ? '9rem 0 4rem 0' : 0)};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface Props {
  gatsbyImage?: GatsbyTypes.GatsbyImageSharpFluidFragment
  title?: string
  subtitle?: string
  cta?: string
  link?: string
  height?: Size
}

const FullImageHero: React.FC<Props> = ({
  gatsbyImage,
  title,
  subtitle,
  cta,
  link = '/products',
  height = 'fixed',
}) => {
  return (
    <>
      <HeroImage fluid={gatsbyImage} size={height}>
        <TextWrapper size={height}>
          {title && <h1>{title}</h1>}
          {subtitle && <p>{subtitle}</p>}
          {cta && (
            <LargeButton link={link} text={cta} background="christmasGreen" />
          )}
        </TextWrapper>
      </HeroImage>
    </>
  )
}

export default FullImageHero
