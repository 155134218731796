import React from 'react'
import Fade from 'react-reveal/Fade'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { breakpointsDown, colors, spacings } from '../../styling/constants'
import LargeButton from '../LargeButton'
import ProductPreview from '../productPreview'

interface Props {
  products?: GatsbyTypes.Maybe<
    readonly GatsbyTypes.Maybe<GatsbyTypes.ProductFragment>[]
  >
  title?: string
  body?: string
  locale?: string
}

const Body = styled.p`
  width: 100%;
  opacity: 0.8;
  max-width: 40rem;
`

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2.8rem;

  @media ${breakpointsDown.desktop} {
    flex-direction: column;
  }

  & > * {
    width: 27%;
    margin-right: 2rem;

    @media ${breakpointsDown.desktop} {
      width: 100%;
      margin: 0 0 2rem 0;
    }
  }
`

const IndexCategory: React.FC<Props> = ({ products, title, body, locale }) => {
  return (
    <div>
      <Fade>
        <h3>{title}</h3>
      </Fade>
      <Fade>
        <Body>{body}</Body>
      </Fade>
      <Fade>
        <ProductWrapper>
          {products &&
            products.map((product) => (
              <ProductPreview
                product={product}
                key={product?.id}
                locale={locale}
              />
            ))}
        </ProductWrapper>
      </Fade>
    </div>
  )
}

export default IndexCategory
