import React from 'react'
import { injectIntl } from 'react-intl';
import belts from "../../images/belts.svg"
import ImageHeadline from './ImageHeadline'

const FreeShipping = ({intl}) => (
  <ImageHeadline image={ belts } title={intl.formatMessage({id: 'free-shipping.title'})} body={intl.formatMessage({id: 'free-shipping.body'})} />
)

export default injectIntl(FreeShipping)
