import { useEffect, useState } from 'react';
import axios from 'axios';

const useInstagramFeed = () => {
  const [images, setResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.GATSBY_KCO_URL}/instagramFeed`);
        setResponse(response.data.map((dataItem) => dataItem.media_url));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  return images;
};

export default useInstagramFeed;
