import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Button from '../button';
import useInstagramFeed from '../../utilities/useInstagramFeed';
import {
  colors, breakpointsDown, fonts, ProductDescription,
} from '../../styling/constants';
import LazyLoad from 'react-lazy-load'
import { FormattedMessage } from 'react-intl';

const ContentWrapper = styled.div`
    color: ${colors.darkGray};
`;

const Title = styled.h3`
    margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
    width: 20rem;
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${breakpointsDown.tablet} {
        width: 45%;
    }
`

const ImageWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 3.5rem;
    align-items: center;
`

const InstaImage = styled.img`
    max-width: 20rem;
    border: 6px solid white;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    @media ${breakpointsDown.tablet} {
        width: 45%;
        max-width: none;
        border: 3px solid white;
    }
`

const InstagramShowcase = ({ locale }) => {
  const images = useInstagramFeed();

  return (
    <ContentWrapper>
      <Title>
        <FormattedMessage id="index.stay-up-to-date" />
      </Title>
      <ProductDescription>
        <FormattedMessage id="index.on-instagram" />
      </ProductDescription>
      <ImageWrapper>
        { images && images.map((url) => (
          <LazyLoad key={url} debounce={false}>
            <InstaImage alt="Instagram image" src={url} />
          </LazyLoad>
        ))}
        <ButtonWrapper>
          <a href="https://www.instagram.com/beltofsweden/" rel="noopener noreferrer" target="_blank">
            <FormattedMessage id="index.see-more-insta">
              {(translation) => <Button color="dark" text={translation} />}
            </FormattedMessage>
          </a>
        </ButtonWrapper>
      </ImageWrapper>
    </ContentWrapper>
  );
};

export default InstagramShowcase;
